<template>
  <section id="conceptSection" class="inner">
    <h1 v-html="$t('concept.title')" />
    <p class="detail" v-html="$t('concept.detail')" />
    <div class="bar" />
    <div class="boxs">
      <div class="box">
        <div class="img img1" />
        <h2 v-html="$t('concept.majorFeature1.title')" />
        <p v-html="$t('concept.majorFeature1.detail')" />
      </div>
      <div class="box">
        <div class="img img2" />
        <h2 v-html="$t('concept.majorFeature2.title')" />
        <p v-html="$t('concept.majorFeature2.detail')" />
      </div>
      <div class="box">
        <div class="img img3" />
        <h2 v-html="$t('concept.majorFeature3.title')" />
        <p v-html="$t('concept.majorFeature3.detail')" />
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="sass" scoped>
@import 'src/assets/sass/variable'
@import 'src/assets/sass/mixins'
@import 'src/assets/sass/reset'
@import 'src/assets/sass/commons'

.inner
  +flex(center)
  flex-direction: column
  flex-wrap: wrap
  padding: 80px 0
  .boxs
    +flex(space-between, flex-start)
    +size(100%, auto)
    .box
      width: 30%
      +flex(center)
      flex-direction: column
      flex-wrap: wrap
      h2
        margin-bottom: 20px
      p
        +fs(l)
        +lh(20)
      .img
        +size(100%, 0, 20px)
        padding-bottom: 100%
        margin-bottom: 40px
        box-shadow: 0px 5px 10px rgba(33,33,33,0.16)
        background-position: center center
        background-size: cover
        background-repeat: no-repeat
        &.img1
          background-image: url("../../assets/imgs/concept1.jpg")
        &.img2
          background-image: url("../../assets/imgs/concept2.jpg")
        &.img3
          background-image: url("../../assets/imgs/concept3.jpg")
+mq(ipadAir)
  .boxs
    flex-direction: column !important
    flex-wrap: wrap !important
    justify-content: center !important
    .box
      width: 80% !important
      margin: 0 10% !important
      margin-bottom: 80px !important
      &:last-child
        margin-bottom: 0 !important
      .img
        order: 1
        margin: 20px 0 !important
      h2
        order: 0
      p
        order: 2
+mq(iphoneProMax)
  .detail
    text-align: left !important
  .boxs
    .box
      width: 100% !important
      margin: 0 !important
      margin-bottom: 80px !important
      &:last-child
        margin-bottom: 0 !important
</style>
