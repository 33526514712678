<template>
  <section id="contactSection" class="inner">
    <h1 v-html="$t('contact.title')" />
    <p class="detail" v-html="$t('contact.detail')" />
    <div class="formWrapper" v-if="!showSendResult">
      <Form @submit="onSubmit" :validation-schema="schema">
        <TextInput name="name" type="text" :label="$t('contact.name')" />
        <TextInput name="email" type="email" :label="$t('contact.email')" />
        <SelectInput
          name="title"
          :label="$t('contact.contactTitle')"
          :default="$t('contact.options.option1')"
          :options="options"
          @updateOption="updateTitle"
        />
        <TextareaInput name="content" :label="$t('contact.content')" />
        <button class="btn" type="submit">{{ $t("contact.btn") }}</button>
      </Form>
    </div>
    <div class="result" v-if="showSendResult">
      <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" v-bind:svg-inline="''" v-bind:class="'icon'" v-if="isSuccess" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g data-name="グループ 13938" transform="translate(-1121 -5370)"><circle data-name="楕円形 385" cx="50" cy="50" r="50" transform="translate(1121 5370)" fill="#4792de"/><path data-name="パス 16539" d="M1149.159 5423.382l14.078 14.078 28.157-28.156" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="10"/></g></svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" v-bind:svg-inline="''" v-bind:class="'icon'" v-if="!isSuccess" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g data-name="グループ 13940" transform="translate(-10640 -6158)"><circle data-name="楕円形 385" cx="50" cy="50" r="50" transform="translate(10640 6158)" fill="#e25454"/><g data-name="グループ 13939" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="10"><path data-name="線 416" d="M10675 6193l30 30"/><path data-name="線 417" d="M10705 6193l-30 30"/></g></g></svg>
      <p class="detail" v-html="resultText" />
    </div>
  </section>
</template>

<script>
import { Form } from "vee-validate";
import * as Yup from "yup";
import TextInput from "../commons/textInput";
import SelectInput from "../commons/selectInput";
import TextareaInput from "../commons/textareaInput";
import { localeJP } from "../../config/yup.locale.ja";
import axios from "axios";

Yup.setLocale(localeJP);

export default {
  name: "App",
  components: {
    Form,
    TextInput,
    SelectInput,
    TextareaInput,
  },
  data() {
    return {
      options: [
        {
          name: this.$t("contact.options.option1"),
          value: this.$t("contact.options.option1"),
        },
        {
          name: this.$t("contact.options.option2"),
          value: this.$t("contact.options.option2"),
        },
        {
          name: this.$t("contact.options.option3"),
          value: this.$t("contact.options.option3"),
        },
        {
          name: this.$t("contact.options.option4"),
          value: this.$t("contact.options.option4"),
        },
        {
          name: this.$t("contact.options.option5"),
          value: this.$t("contact.options.option5"),
        },
      ],
      titleValue: this.$t("contact.options.option1"),

      showSendResult: false,
      isSuccess: true,
      resultText: this.$t("contact.success"),
    };
  },
  setup() {
    // Using yup to generate a validation schema
    // https://vee-validate.logaretm.com/v4/guide/validation#validation-schemas-with-yup
    const schema = Yup.object().shape({
      name: Yup.string().required(),
      email: Yup.string().email().required(),
      title: Yup.string().required(),
      content: Yup.string().required(),
    });

    return {
      schema,
    };
  },
  methods: {
    updateTitle(value) {
      this.titleValue = value;
    },
    onSubmit(values, { resetForm }) {
      values.title = this.titleValue;
      const encodeForm = (data) => {
        return Object.keys(data)
          .map(
            (key) =>
              encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
          )
          .join("&");
      };

      var url = "";
      if (process.env.NODE_ENV == "development") {
        url = "https://hooks.zapier.com/hooks/catch/10153563/bo9qch1/";
      } else {
        url = "https://hooks.zapier.com/hooks/catch/10153563/bbuj8ro/"
      }

      axios
        .post(
          url,
          encodeForm(values),
          { headers: { Accept: "application/json" } }
        )
        .then(() => {
          this.isSuccess = true;
          this.resultText = this.$t("contact.success");
          this.showSendResult = true;
          this.titleValue = this.$t("contact.options.option1");
          resetForm();
          this.countDownTimer = setTimeout(() => {
            this.showSendResult = false;
          }, 5000);
        })
        .catch(() => {
          this.isSuccess = false;
          this.resultText = this.$t("contact.failed");
          this.showSendResult = true;
        });
    },
  },
};
</script>

<style lang="sass" scoped>
@import 'src/assets/sass/variable'
@import 'src/assets/sass/mixins'
@import 'src/assets/sass/reset'
@import 'src/assets/sass/commons'

.inner
  +flex(center)
  flex-direction: column
  flex-wrap: wrap
  padding: 80px 0
  .formWrapper
    +size(60%, auto)
    margin-top: 40px
    .btn
      +size(200px, 48px, 24px)
      +ffw(medium)
      box-sizing: border-box
      cursor: pointer
      border: 2px solid $gs2
      line-height: 44px
      opacity: 1
      color: $gs2
      background-color: transparent
      margin: 0 calc(50% - 100px)
      transition: 0.3s
      &:hover
        +ffw(bold)
        background-color: $color1
        border: 2px solid $color1
        color: $gs1
  .result
    +size(60%, auto, 8px)
    +flex(center)
    background-color: $gs3
    flex-direction: column
    flex-wrap: wrap
    padding: 60px 0
    margin-top: 80px
    .icon
      +size(100px)
      margin-bottom: 40px

+mq()
  .formWrapper
    width: 100% !important
  .result
    width: 100% !important
</style>
