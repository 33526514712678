<template>
  <section id="premiumSection" class="inner">
    <h1 v-html="$t('premium.title')" />
    <p class="detail" v-html="$t('premium.detail')" />
    <div class="bar" />
    <div class="compare">
      <h2 v-html="$t('premium.compare.title')" />
      <table>
        <tr>
          <th></th>
          <td class="free">{{ $t("premium.compare.detail.free") }}</td>
          <td class="premium">{{ $t("premium.compare.detail.premium") }}</td>
        </tr>
        <tr>
          <th>{{ $t("premium.compare.detail.fileLimit") }}</th>
          <td class="free">5{{ $t("premium.compare.detail.case") }}</td>
          <td class="premium">30{{ $t("premium.compare.detail.case") }}</td>
        </tr>
        <tr>
          <th>{{ $t("premium.compare.detail.length") }}</th>
          <td class="free">3{{ $t("premium.compare.detail.minute") }}</td>
          <td class="premium">10{{ $t("premium.compare.detail.minute") }}</td>
        </tr>
        <tr>
          <th>{{ $t("premium.compare.detail.video") }}</th>
          <td class="free">3{{ $t("premium.compare.detail.case") }}</td>
          <td class="premium">{{ $t("premium.compare.detail.unlimited") }}</td>
        </tr>
        <tr>
          <th>{{ $t("premium.compare.detail.download") }}</th>
          <td class="free">✕</td>
          <td class="premium">〇</td>
        </tr>
        <tr>
          <th>{{ $t("premium.compare.detail.lastLetter") }}</th>
          <td class="free">1{{ $t("premium.compare.detail.case") }}</td>
          <td class="premium">{{ $t("premium.compare.detail.unlimited") }}</td>
        </tr>
        <tr>
          <th>{{ $t("premium.compare.detail.lastLetterDelivery") }}</th>
          <td class="free">1{{ $t("premium.compare.detail.case") }}</td>
          <td class="premium">{{ $t("premium.compare.detail.unlimited") }}</td>
        </tr>
        <tr>
          <th>{{ $t("premium.compare.detail.timeMachine") }}</th>
          <td class="free">3{{ $t("premium.compare.detail.case") }}</td>
          <td class="premium">{{ $t("premium.compare.detail.unlimited") }}</td>
        </tr>
        <tr>
          <th>{{ $t("premium.compare.detail.timeMachineDelivery") }}</th>
          <td class="free">3{{ $t("premium.compare.detail.case") }}</td>
          <td class="premium">{{ $t("premium.compare.detail.unlimited") }}</td>
        </tr>
      </table>
    </div>
    <div class="bar" />
    <div class="useage">
      <h2 v-html="$t('premium.useage.title')" />
      <p class="detail" v-html="$t('premium.useage.detail')" />
      <div class="stepWrapper">
        <div class="stepBox">
          <div class="step">1</div>
          <p class="detail" v-html="$t('premium.useage.step1')" />
          <phoneimg class="img" :imgName="'step1'" :phoneWidth="phoneWidth" />
        </div>
        <div class="stepBox">
          <div class="step">2</div>
          <p class="detail" v-html="$t('premium.useage.step2')" />
          <phoneimg class="img" :imgName="'step2'" :phoneWidth="phoneWidth" />
        </div>
        <div class="stepBox">
          <div class="step">3</div>
          <p class="detail" v-html="$t('premium.useage.step3')" />
          <phoneimg class="img" :imgName="'step3'" :phoneWidth="phoneWidth" />
        </div>
      </div>
      <p class="note" v-html="$t('premium.useage.note')" />
    </div>
  </section>
</template>

<script>
import Phoneimg from "../commons/phoneImg.vue";

export default {
  components: {
    phoneimg: Phoneimg,
  },
  computed: {
    phoneWidth() {
      var innerW = document.documentElement.clientWidth;
      if (innerW >= 1000) {
        return 285;
      } else if ((innerW < 1000 && innerW >= 834)) {
        return 220;
      } else if ((innerW < 834 && innerW >= 500)) {
        return 400;
      } else if ((innerW < 500 && innerW >= 375)) {
        return innerW - 60;
      } else {
        return innerW - 40;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
@import 'src/assets/sass/variable'
@import 'src/assets/sass/mixins'
@import 'src/assets/sass/reset'
@import 'src/assets/sass/commons'

.inner
  +flex(center)
  flex-direction: column
  flex-wrap: wrap
  padding: 80px 0
  table
    border-collapse: collapse
    border: 2px solid $gs1
    margin-top: 40px
    th
      +fs(l)
      height: 60px
      padding: 0 30px
      text-align: left
      border: 1px solid $gs5
      vertical-align: middle
    td
      +fs(l)
      +size(150px, 60px)
      text-align: center
      vertical-align: middle
      border: 1px solid $gs5
      &.premium
        +ffw(bold)
        color: $color1
  .useage
    +size(100%, auto)
    h2
      margin-bottom: 20px
    .stepWrapper
      +flex(space-between, flex-start)
      margin-top: 60px
      .stepBox
        +size(285px, auto)
        +flex(center, center)
        flex-direction: column
        flex-wrap: wrap
        .step
          +size(56px, 56px, 50%)
          +fs(xl)
          +ffw(bold)
          color: $gs1
          background-color: $color1
          line-height: 56px
          text-align: center
          margin-bottom: 40px
        .img
          margin-top: 40px
    .note
      margin-top: 80px
      color: $gs4
      text-align: center
      +lh(18)
      +fs(s)

+mq(ipadAir)
  .inner
    table
      width: 100% !important
      th
        width: 40% !important
        padding: 20px 0 !important
        padding-right: 10px !important
        height: auto !important
        line-height: 1.6
      td
        width: auto !important
        padding: 0 10px !important
  .useage
    .stepWrapper
      flex-direction: column !important
      flex-wrap: wrap !important
      justify-content: center !important
      .stepBox
        width: 100% !important
        margin-bottom: 80px
        &:last-child
          margin-bottom: 0 !important

+mq(iphone5)
  .inner
    table
      th
        width: 35% !important
</style>
