<template>
  <div class="selectWrapper">
    <div class="selectBox">
      <div
        tabindex="0"
        @mousedown="openOption"
        @focus="onFocus"
        @blur="onBlur"
        class="select"
        :class="[
          isOpen == true ? 'open' : '',
          currentValue != '' ? 'hasData' : '',
        ]"
      >
        <p class="title" v-text="label" />
        <p class="value" v-text="currentValue" />
      </div>
      <transition name="expand">
        <div class="optionBox" v-if="isOpen">
          <ul class="options">
            <li
              class="option"
              v-for="(option, index) in options"
              :key="index"
              @mousedown="updateOption(option.name, option.value)"
              v-text="option.name"
            />
          </ul>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>

export default ({
  emits: ['updateOption'], 
  data() {
    return {
      isOpen: false,
      displayName: "",
    };
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    default: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      function() {
        return [];
      },
    },
  },
  computed: {
    currentValue() {
      if (this.displayName === "") return this.default;
      return this.displayName;
    },
  },
  methods: {
    updateOption(name, value) {
      this.displayName = name;
      this.isOpen = !this.isOpen;
      this.$emit("updateOption", value);
    },
    updateValue(e) {
      this.$emit("input", e.target.value);
    },
    onFocus() {
      this.isOpen = true;
    },
    onBlur() {
      this.isOpen = false;
    },
    openOption() {
      this.isOpen = !this.isOpen;
    }
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import 'src/assets/sass/variable'
@import 'src/assets/sass/mixins'
@import 'src/assets/sass/reset'
@import 'src/assets/sass/commons'

.selectWrapper
  position: relative
  height: 76px
  margin-bottom: 20px
  p
    +fs(l)
    +ffw(bold)
    margin-bottom: 10px
  .selectMob
    +size (100%, 50px, 5px)
    border: solid 1px $gs4
    position: relative
    overflow: hidden
    display: none
    select
      +size (100%)
      +fs(l)
      background-color: $gs1
      padding: 0 20px
      box-sizing: border-box
      line-height: 50px
    &:before
      +abpos(0, 0, 55px, 0)
      +size(100%, 2px)
      content: ""
      background-color: $gs4
    &:after
      +size (15px)
      +abpos(initial, 15px, 17.5px, initial)
      content: ""
      background: url('../../assets/imgs/selectArrow.svg') no-repeat center center
      transition: 0.5s
    &.open
      &:before
        background-color: $color2
      &:after
        transform: rotate(180deg)
    &.disabled
      background-color: rgba($gs4, 0.2)
      &:hover
        cursor: auto
  .selectBox
    position: relative
    .select
      +size(100%, 56px)
      border-radius: 8px 8px 0 0
      background-color: $gs3
      padding: 0 20px
      padding-top: 16px
      box-sizing: border-box
      cursor: pointer
      &:before
        +abpos(0, 0, 55px, 0)
        +size(100%, 2px)
        content: ""
        background-color: $gs4
      &:after
        +size (24px)
        +abpos(initial, 12px, 16px, initial)
        content: ""
        background: url('../../assets/imgs/selectArrow.svg') no-repeat center center
        transition: 0.3s
      .title
        +fs(m)
        +ffw(normal)
        +abpos(14px, initial, 17px, initial)
        color: rgba($gs2, 0.5)
        padding: 5px 5px
        transition: 0.2s
        cursor: text
      .value
        +fs(m)
        +ffw(normal)
        padding-top: 16px
      &.open
        &:before
          background-color: $color2
        &:after
          transform: rotate(180deg)
        .title
          +fs(xs)
          +ffw(medium)
          +abpos(14px, initial, 4px, initial)
          transition: 0.2s
          color: $color2
          &.error
            color: $color1
      &.hasData
        .title
          +fs(xs)
          +ffw()
          +abpos(14px, initial, 4px, initial)
          transition: 0.2s
          color: $gs4
    .optionBox
      +abpos(0, initial, 60px, initial)
      +size (100%, auto)
      border-radius: 0 0 8px 8px
      background-color: $gs3
      z-index: 400
      box-shadow: 0px 3px 6px rgba(33,33,33,0.1)
      .options
        max-height: 300px
        overflow-y: scroll
        li
          +size (100%, auto)
          padding: 17px 20px
          cursor: pointer
          border-bottom: none
          position: relative
          &:before
            +abpos(0, 0, 47px, 0)
            +size(100%, 1px)
            content: ""
            background-color: $gs1
          &:hover
            background-color: rgba($gs4,0.2)
          &:last-child
            border-radius: 0 0 8px 8px
            &:before
              +size(0)
  span.error
    +fs(xs)
    +lh(14)
    margin-top: 4px
    color: $color1
    display: block
</style>
