<template>
  <div class="inputBox">
    <div class="textInput" :class="isFocus == true ? 'focus' : ''">
      <input
        :id="name"
        :type="type"
        :value="inputValue"
        @input="handleChange"
        @blur="onBlur"
        @focus="onFocus"
        ref="r"
        :label="name"
        :aria-label="name"
      />
      <label
        @click="inputOnFous"
        :class="[
          isFocus == true ? 'focus' : '',
          inputValue != '' ? 'hasData' : ''
        ]"
        :for="name"
        class="title"
      >
        {{ label }}
      </label>
    </div>
    <span class="error" v-show="errorMessage">{{ errorMessage }}</span>
  </div>
</template>

<script>
import { useField } from "vee-validate";

export default {
  data() {
    return {
      isFocus: false,
    };
  },
  props: {
    type: {
      type: String,
      default: "text",
    },
    value: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    // we don't provide any rules here because we are using form-level validation
    // https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
    const {
      value: inputValue,
      errorMessage,
      handleChange,
      meta,
    } = useField(props.name, undefined, {
      initialValue: props.value,
    });

    return {
      handleChange,
      errorMessage,
      inputValue,
      meta,
    };
  },
  methods: {
    onFocus() {
      this.isFocus = true;
    },
    onBlur() {
      this.isFocus = false;
    },
    inputOnFous() {
      this.$nextTick(() => this.$refs.r.focus());
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import 'src/assets/sass/variable'
@import 'src/assets/sass/mixins'
@import 'src/assets/sass/reset'
@import 'src/assets/sass/commons'

.inputBox
  display: flex
  flex-wrap: wrap
  height: 76px
  margin-bottom: 20px
  .textInput
    +size(100%, 56px)
    position: relative
    &:before
      +abpos(0, 0, 55px, 0)
      +size(100%, 2px)
      content: ""
      background-color: $gs4
    &.focus
      &:before
        background-color: $color2
    input
      +size(100%)
      +fs(m)
      border-radius: 8px 8px 0 0
      background-color: $gs3
      padding: 0 20px
      padding-top: 16px
      box-sizing: border-box
      &.error
        box-shadow: 0 0 0 1px $color3
      &[disabled]
        background-color: rgba($gs4, 0.2)
    .title
      +fs(m)
      +ffw(normal)
      +abpos(14px, initial, 17px, initial)
      color: rgba($gs2, 0.5)
      padding: 5px 5px
      transition: 0.2s
      cursor: text
      &.hasData
        +fs(xs)
        +ffw()
        +abpos(14px, initial, 4px, initial)
        transition: 0.2s
        color: $gs4
        &.error
          color: $color3
      &.focus
        +fs(xs)
        +ffw(medium)
        +abpos(14px, initial, 4px, initial)
        transition: 0.2s
        color: $color2
        &.error
          color: $color1
  span.error
    +fs(xs)
    +lh(14)
    margin-top: 4px
    color: $color1
    display: block
</style>
