<template>
  <section id="featureSection" class="sectionWrapper">
    <div class="inner">
      <h1 v-html="$t('feature.title')" />
      <div class="pc" v-show="!isSmall">
        <div class="featureWrapper">
          <ul class="navBox">
            <li
              class="nav"
              v-for="feature in features"
              :class="feature.selected ? 'selected' : ''"
              :key="feature.key"
              @click="manualChange(feature)"
              v-text="$t('feature.' + feature.key + '.title')"
            />
          </ul>
        </div>
        <div class="contentBox">
          <div class="imgBox content">
            <phoneimg
              class="img"
              :imgName="currentFeature.key"
              :phoneWidth="phoneWidth"
            />
          </div>
          <div class="textBox content">
            <h2 v-text="$t('feature.' + currentFeature.key + '.title')" />
            <p
              class="detail"
              v-text="$t('feature.' + currentFeature.key + '.detail')"
            />
          </div>
        </div>
      </div>
      <div class="mob" v-show="isSmall">
        <div class="contentBox" v-for="feature in features" :key="feature.key">
          <div class="imgBox content">
            <phoneimg
              class="img"
              :imgName="feature.key"
              :phoneWidth="phoneWidth"
            />
          </div>
          <div class="textBox content">
            <h2 v-text="$t('feature.' + feature.key + '.title')" />
            <p
              class="detail"
              v-text="$t('feature.' + feature.key + '.detail')"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Phoneimg from "../commons/phoneImg.vue";

export default {
  components: {
    phoneimg: Phoneimg,
  },
  data() {
    return {
      isSmall: false,
      playTimer: "",
      countDownTimer: "",
      currentFeature: { index: 0, key: "memory", selected: true },
      features: [
        { index: 0, key: "memory", selected: true },
        { index: 1, key: "timeMachine", selected: false },
        { index: 2, key: "movie", selected: false },
        { index: 3, key: "lastLetter", selected: false },
        { index: 4, key: "endingNote", selected: false },
        { index: 5, key: "privacy", selected: false },
        { index: 6, key: "heart", selected: false },
        { index: 7, key: "anniversary", selected: false },
      ],
    };
  },
  mounted() {
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);
    this.autoplay();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkScreenSize);
  },
  computed: {
    nextFeature() {
      const currentIndex = this.currentFeature.index;
      let next = currentIndex + 1;
      if (next == this.features.length) {
        next = 0;
      }
      const nextFeature = this.features[next];
      return nextFeature;
    },
    phoneWidth() {
      var innerW = document.documentElement.clientWidth;
      if (innerW >= 500) {
        return 300;
      } else if (innerW < 500 && innerW >= 375) {
        return innerW - 60;
      } else {
        return innerW - 40;
      }
    },
  },
  methods: {
    manualChange(feature) {
      this.change(feature);
      this.stopAutoplay();
    },
    change(feature) {
      this.currentFeature = feature;
      const currentIndex = feature.index;
      for (var i in this.features) {
        this.features[i].selected = false;
      }
      this.features[currentIndex].selected = true;
    },
    autoplay() {
      this.playTimer = setInterval(() => {
        if (this.isSmall) {
          clearTimeout(this.playTimer);
          return;
        }
        this.change(this.nextFeature);
      }, 5000);
    },
    stopAutoplay() {
      clearTimeout(this.countDownTimer);
      clearTimeout(this.playTimer);
      this.autoplayCountDown();
    },
    autoplayCountDown() {
      this.countDownTimer = setTimeout(() => {
        this.autoplay();
      }, 10000);
    },
    checkScreenSize() {
      var innerW = document.documentElement.clientWidth;
      if (innerW >= 600) {
        this.isSmall = false;
      } else {
        this.isSmall = true;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
@import 'src/assets/sass/variable'
@import 'src/assets/sass/mixins'
@import 'src/assets/sass/reset'
@import 'src/assets/sass/commons'

.sectionWrapper
  background-color: $gs3
  .inner
    padding: 80px 0
    .featureWrapper
      text-align: center
      .navBox
        +size(100%, auto)
        display: inline-block
        .nav
          +size(auto, 48px, 24px)
          +fs(l)
          +ffw(medium)
          color: $gs2
          background-color: transparent
          text-align: center
          border: 2px solid $gs2
          padding: 0 20px
          cursor: pointer
          margin: 0 10px
          margin-bottom: 20px
          line-height: 44px
          transition: 0.3s
          display: inline-block
          &.selected
            +ffw(bold)
            color: $gs1
            background-color: $color1
            border: 2px solid $color1
          &:hover
            +ffw(bold)
            color: $gs1
            background-color: $color1
            border: 2px solid $color1
    .contentBox
      +flex(flex-start, flex-start)
      +size(80%, auto)
      margin: 0 10%
      margin-top: 60px
      .content
        &.imgBox
          +flex(center)
          +size(40%, auto)
        &.textBox
          +size(60%, auto)
          padding: 0 50px
          padding-top: 100px
          h2
            text-align: left
            margin-bottom: 20px
          .detail
            text-align: left

+mq()
  .contentBox
    width: 100% !important
    flex-direction: column !important
    flex-wrap: wrap !important
    justify-content: center !important
    margin: 0 !important
    .content
      width: 100% !important
      &.imgBox
        order: 1 !important
      &.textBox
        order: 0 !important
        padding: 0 !important
        padding-top: 0 !important
        margin-bottom: 80px
+mq(iphoneProMax)
  .contentBox
    margin-bottom: 80px !important
    &:last-child
      margin-bottom: 0 !important
    .content
      &.textBox
        margin-bottom: 40px !important
</style>