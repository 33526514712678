<template>
  <Header />
  <Main />
  <Concept />
  <Feature />
  <Premium />
  <Help />
  <Contact />
  <Footer />
</template>

<script>
import Header from "./components/views/header";
import Main from "./components/views/Main";
import Concept from "./components/views/Concept";
import Feature from "./components/views/Feature";
import Premium from "./components/views/Premium";
import Help from "./components/views/Help";
import Contact from "./components/views/Contact";
import Footer from "./components/views/footer";

export default {
  name: "App",
  components: {
    Header,
    Main,
    Concept,
    Feature,
    Premium,
    Help,
    Contact,
    Footer,
  },
  mounted() {
    var meta = document.createElement("meta");
    meta.name = "robots";
    meta.content = "noindex, nofollow";
    if (process.env.NODE_ENV == "development") {
      document.head.appendChild(meta);
    }
  },
};
</script>

<style lang='sass'>
@import 'src/assets/sass/variable'
@import 'src/assets/sass/mixins'
@import 'src/assets/sass/reset'
@import 'src/assets/sass/commons'
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap')
</style>