<template>
  <footer>
    <div class="footerWrapper">
      <div class="terms">
        <a href="https://public.aimemo.app/terms.pdf" target="_blank" v-html="$t('footer.terms')"/>
        <a href="https://public.aimemo.app/privacy.pdf" target="_blank" v-html="$t('footer.policy')"/>
        <a href="https://public.aimemo.app/commercial.pdf" target="_blank" v-html="$t('footer.sct')"/>
      </div>
      <p class="copyright" v-html="$t('footer.copyright')"/>
    </div>
  </footer>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import 'src/assets/sass/variable'
@import 'src/assets/sass/mixins'
@import 'src/assets/sass/reset'
@import 'src/assets/sass/commons'

footer
  +size(100%, 80px)
  padding: 30px 100px
  background-color: $gs2
  .footerWrapper
    +flex(space-between)
    flex-direction: row-reverse
    .copyright
      +fs(xs)
      color: $gs1
      line-height: 20px
    .terms
      +flex()
      a
        +fs(s)
        color: $gs1
        line-height: 20px
        margin: 0 20px
        position: relative
        text-decoration: underline
        &:hover
          font-weight: bold

+mq()
  footer
    height: auto !important
    padding: 30px !important
    .footerWrapper
      flex-direction: column
      flex-wrap: wrap
      .copyright
        +size(100%, auto)
      .terms
        +size(100%, auto)
        flex-direction: column
        flex-wrap: wrap
        margin-bottom: 30px
        a
          +size(100%, auto)
          margin: 0 !important
          padding: 10px 0

+mq(iphoneProMax)
  footer
    padding: 30px 20px !important
    .footerWrapper
      flex-direction: column !important
      flex-wrap: wrap !important
</style>
