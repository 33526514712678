import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import VueGtag from "vue-gtag"

const i18n = createI18n({
  locale: 'ja',
  fallbackLocale: 'ja',
  messages: {
    // 翻訳用のjson
    ja : require('./lang/text.json'),
  }
})

const app = createApp(App)

app.use(i18n)
app.use(VueGtag, {
  config: { id: "G-8ZVERTGTYL" }
})
app.mount('#app')
