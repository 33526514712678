<template>
  <section id="helpSection" class="sectionWrapper">
    <div class="inner">
      <h1 v-html="$t('help.title')" />
      <p class="detail" v-html="$t('help.detail')" />
      <a href="https://aimemori.zendesk.com/hc/ja" target="_blank" class="btn" v-html="$t('help.btn')" />
    </div>
  </section>
</template>

<style lang="sass" scoped>
@import 'src/assets/sass/variable'
@import 'src/assets/sass/mixins'
@import 'src/assets/sass/reset'
@import 'src/assets/sass/commons'

.sectionWrapper
  background-color: $gs3
  .inner
    +flex(center)
    flex-direction: column
    flex-wrap: wrap
    padding: 80px 0
    .detail
      margin-bottom: 40px
    .btn
      +size(200px, 48px, 24px)
      +ffw(medium)
      box-sizing: border-box
      text-align: center
      border: 2px solid $gs2
      transition: 0.3s
      line-height: 44px
      color: $gs2
      &:hover
        background-color: $color1
        border: 2px solid $color1
        +ffw(bold)
        color: $gs1
</style>